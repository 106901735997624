import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Guidance for using different data types as inputs to KOPE Activities.`}</p>
    </PageDescription>
    <h2>{`Supported Parameter Types`}</h2>
    <p>{`A KOPE Activity is expected to have a set of inputs and outputs, called parameters. These parameters are identified with the method’s arguments (inputs) and return value (output).`}</p>
    <p>{`Currently, a limited set of CLR types are supported as parameters. In future stages additional types will be supported.
Find below a list of supported parameter types for KOPE activities.`}</p>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Strings</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Enumerations</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Booleans</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Integers and Doubles</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Collections</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Objects</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Units</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Files</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Models</AnchorLink>
  <AnchorLink mdxType="AnchorLink">GeoJSON</AnchorLink>
    </AnchorLinks>
    <h2>{`Strings`}</h2>
    <p>{`String inputs will map to the UI as simple Input boxes (only UTF-8 strings are supported).`}</p>
    <h3>{`Collections of Strings`}</h3>
    <p>{`A collection of strings, (such as `}<inlineCode parentName="p">{`string[]`}</inlineCode>{` or any type inheriting from `}<inlineCode parentName="p">{`IEnumerable<>`}</inlineCode>{`) will be generated as an `}<inlineCode parentName="p">{`ArraySchema`}</inlineCode>{`. To only process unique string inputs, any type implementing the `}<inlineCode parentName="p">{`ISet<>`}</inlineCode>{` interface can be used.`}</p>
    <h3>{`Mapping to Outputs`}</h3>
    <p>{`Collections are rendered as tables in KOPE, with each property of the type being mapped to its own column.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "43.40277777777778%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAACKklEQVQoz53P3UtTARzG8XMVXrTZlm4et+k5R7c2rTBdKgpJb/YCRWR/jFfDaaigHg9EF0EvhNBFt2ngvPBMqKAL6aIgMKKYzTa3s5dzNl+yvuFZUNf94APP1cPzE37kUuynPnCQT/Frt8rPHYuDqgn7O3z++J7V5SWSiZfoiUX0xBKry4skE0us6askdd22lkyysrLCxsYGQu7tc4z1F1hf19nbMdirGljlbWCfOXUWt0ekWW7Hq7ThlRUaJZmA34coirgavRz3NOFt9lFXV0csFkPYSr0jky+QyWbIfkmSSX9iczNlF8/P3MXja2Ggv4trfWGuRk9w5ewJbg1EuN4X4fyZEBe7Q1yKRmhoaGB8fBwhm82Rz+UwzQrl4ndMs0yxZAIHaOoUHtFPVyRIb6dCtEOhJyzTE5aIRmR6OxRb/6kgLpeLeDyOkE6nydmFFiXjG2a5SLFYsl9W56YRW2TaT3YS7AjRHmpDliRaJQnRH0D0+RF9AQKtEg6Hg/jYGIJhGBQKBcqmhVnc+mch3NOmGIyKjNyIcHu4jZHLCneGFW5ekAkFFWRZRlEOcxC3283ExARCpVKhpkqlvGUrF9LALjPTMcLKEYYGmxiKuhjqrudcdz0Dp524jjk56nDgdDrsdwVBYHR09O/CmsOcJ5/fZm+3ypvXr3hwf5pnT+ZYeDTLwuOapw9n0DQVVZ1nXlXRNI3JyUl0XUcolUpYloVpmn/U8uHi/7nfUJHuoWC7hVEAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "files",
        "title": "files",
        "src": "/static/780880961f95184bd3334f65e184e52b/3cbba/tables.png",
        "srcSet": ["/static/780880961f95184bd3334f65e184e52b/7fc1e/tables.png 288w", "/static/780880961f95184bd3334f65e184e52b/a5df1/tables.png 576w", "/static/780880961f95184bd3334f65e184e52b/3cbba/tables.png 1152w", "/static/780880961f95184bd3334f65e184e52b/4c487/tables.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System.Collections.Generic;

namespace kope.Demo
{
    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static bool IsCollectionEmpty(ICollection<int> input)
            => input.Count == 0;

        [kopeActivity]
        public static int ArrayLength(double[] input)
            => input.Length;

        [kopeActivity]
        public static bool NestedArray(string[][] input)
            => true;

        [kopeActivity]
        public static int UniqueStringValues(HashSet<string> inputs)
            => inputs.Count;
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br />{` `}<br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "IsCollectionEmpty",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "integer"
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.IsCollectionEmpty",
    "language": "csharp"
  },
  {
    "name": "ArrayLength",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "number"
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "integer"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.ArrayLength",
    "language": "csharp"
  },
  {
    "name": "NestedArray",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "array",
              "items": [
                {
                  "type": "string"
                }
              ],
              "uniqueItems": false
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.NestedArray",
    "language": "csharp"
  },
  {
    "name": "UniqueStringValues",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "inputs": {
          "type": "array",
          "items": [
            {
              "type": "string"
            }
          ],
          "uniqueItems": true
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "integer"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.UniqueStringValues",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Enumerations`}</h2>
    <p>{`Enumerations can be used for inputs to a KOPE Activity. They are automatically mapped to dropdown menus in the KOPE user interface, like so:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "40.97222222222222%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABi0lEQVQoz5XOT4/SQBiA8Z5J1oPJXigUt0uhHXZRaeDAGTVRL2YvfBqCe+DEJ9RslIXSTlswtCDl72OY1dXEg3GSX2aSSZ731VbZd7J1xnq9Jssy9vu9st1u2Ww2HA4HDsfjw/0Px+MRbTybMo9nSCmZzecq/Ct+8r9H+/JtQpqmymKxYLfbqQ1PE0/BKI4JwhAZhQRB8Ej++ZYS3/dJkgTtk3fHeHRPFEXqI45jZTZ72PrDzQ32yxdcuy61qxqiJhRHOAghEMKhXr9G1/MMBgO0O/8r3sQjkFJNO4VV9Gfw1es3FErPqJwCdVtxnttYVwKnJrAsE8epcH7+lNvbj2ijeEoczUnSpZKmK9LlikWyVMG3795TMi0qtk3VsajaFcURVcxLk2LR4OLC5OzsCf1+H+2zP2J8PyGMIsIwfBTIiCCQdDodzMsyjhAYJYNCsaAUiwV0XSefz2MYBrlcjl6vhzaOfDzPx5v+NvF8pn6ghnS7XZrNJu12m0aj8RfXdWm1WpTLZYbDIT8AXl/cziUBN5AAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "enums.png",
        "title": "enums.png",
        "src": "/static/39ac40e2398c382d74ccb778c4dd0a19/3cbba/enums.png",
        "srcSet": ["/static/39ac40e2398c382d74ccb778c4dd0a19/7fc1e/enums.png 288w", "/static/39ac40e2398c382d74ccb778c4dd0a19/a5df1/enums.png 576w", "/static/39ac40e2398c382d74ccb778c4dd0a19/3cbba/enums.png 1152w", "/static/39ac40e2398c382d74ccb778c4dd0a19/4c487/enums.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`If you need one of the items in the dropdown to have multiple words (have a space) such as “Option one”, you can override the value of that enum member as below.
This needs to be applied to each member in the enum separately, so you can override as many or as few members as you want.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System;
using System.Runtime.Serialization; // this namespace is required for the EnumMember attribute

namespace kope.Demo
{
    public enum DropdownValues
    {
      // this will display as "OptionOne" in the UI dropdown
      OptionOne,

      // this will display as "Option two is cool" in the UI dropdown
      [EnumMember(Value = "Option two is cool")]
      OriginalName,
    }
}

`}</code></pre>
    <h2>{`Booleans`}</h2>
    <p>{`Boolean inputs are mapped to `}<inlineCode parentName="p">{`BooleanSchema`}</inlineCode>{` instances. Default values can also be defined.`}</p>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System;

namespace kope.Demo
{
    public static class AwesomeActivities
    {
        [kopeActivity]
        public static bool AreEqual(this string text1, string text2, bool ignoreCase = true)
        {
            var compare = ignoreCase
                ? StringComparison.InvariantCultureIgnoreCase
                : StringComparison.InvariantCulture;

            return text1.Equals(text2, compare);
        }
    }
}

`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "AreEqual",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "text1": {
          "type": "string",
          "title": "text1"
        },
        "text2": {
          "type": "string",
          "title": "text2"
        },
        "ignoreCase": {
          "type": "boolean",
          "default": true,
          "title": "ignoreCase"
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean",
      "title": "AreEqualOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeActivities.AreEqual",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Integers and Doubles`}</h2>
    <p>{`Any integer or double input is mapped to a `}<inlineCode parentName="p">{`IntegerSchema`}</inlineCode>{` or `}<inlineCode parentName="p">{`NumberSchema`}</inlineCode>{` correspondingly. `}<br /><br />{`  `}</p>
    <p><strong parentName="p">{`Attribute Modifiers`}</strong>{` can be used to affect the kind of numeric input users will be presented with. Below is an example of numeric inputs, with and without attribute modifiers: `}<br /><br /></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1ElEQVQoz43SzW4SURQH8KkPYMGWGKFJW422RSgaPxYmXbmoburGJmx5DruhVKSuZgBfgReoi7ZWow9AjGWAKZXCUJiZO8PMneEiH3O9XDNDSTRu+C3O7p/zkcM02i1DNwxo9Pv9oW0PBgPs+j2B/0MIoS6mol6YpmWaZq/Xwxjbtj100SkwxZogS7KmaapLm/jy7evh55Pjk0+Hfzk+Ojr4eFCr1Smlo9GIqbSqmqrpzuiGruuqqkIIBUEILC57/Quem/7rHu/8vMfn887NeWdv+JiZa5lMmlKKMWYkXUEdZP0LAJDNZlkuk85+YDmO41iOY1mnpvf33+fzeUopIYSpg0tZkoGqttsaQmi8NsZ4qp0v2g3LtAwIh7Y9vi0hZNrwmfzThA6E0C9Xt9vtdDpCuczzfKlYLPJ8gecLBX7sx+mpKIpXYb5akluSAoCiKLIsAwAMCM8rlacbG2uPH62uR1ZWg6HQWjgcDIWCkUh4aWkxFotNOjerzcalKNbFRrN2ntekEjKVs/L3Z0/ubL1YfvXy9tbzwPbmwutN/8MHd+/dX78VCESj0auwbhrOi0xACC3LUhQllXr3NplMplJ7e4l4fDe+60gkEm92dnK53Dj8By6TDhPVBAzXAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "files",
        "title": "files",
        "src": "/static/d9daf25e85df7e39e0dbabc700b7830d/3cbba/numbers.png",
        "srcSet": ["/static/d9daf25e85df7e39e0dbabc700b7830d/7fc1e/numbers.png 288w", "/static/d9daf25e85df7e39e0dbabc700b7830d/a5df1/numbers.png 576w", "/static/d9daf25e85df7e39e0dbabc700b7830d/3cbba/numbers.png 1152w", "/static/d9daf25e85df7e39e0dbabc700b7830d/4c487/numbers.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Attribute Modifiers `}<br /><br /></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.RangeStepAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defines a minimum and maximum range, with optional `}<inlineCode parentName="td">{`Step`}</inlineCode>{` property (default to `}<inlineCode parentName="td">{`1.0`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;

namespace kope.Demo
{
    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static bool IsGreaterThan(this int input, double value = 2.0)
            => input > value;

        [kopeActivity]
        public static bool RangeNumber([RangeStep(0, 100)] this int input)
            => true;

        [kopeActivity]
        public static double MapValue(double min, double max, [RangeStep(0, 1, Step = 0.05)] double factor)
            => (max - min) * factor + min;
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "IsGreaterThan",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "integer",
          "title": "input"
        },
        "value": {
          "type": "number",
          "default": 2.0,
          "title": "value"
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean",
      "title": "IsGreaterThanOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.IsGreaterThan",
    "language": "csharp"
  },
  {
    "name": "RangeNumber",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "integer",
          "minimum": 0,
          "maximum": 100,
          "title": "input"
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean",
      "title": "RangeNumberOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.RangeNumber",
    "language": "csharp"
  },
  {
    "name": "MapValue",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "min": {
          "type": "number",
          "title": "min"
        },
        "max": {
          "type": "number",
          "title": "max"
        },
        "factor": {
          "type": "number",
          "multipleOf": 0.05,
          "minimum": 0.0,
          "maximum": 1.0,
          "title": "factor"
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "number",
      "title": "MapValueOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.MapValue",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Collections`}</h2>
    <p>{`Collections, (such as arrays, or any type inheriting from `}<inlineCode parentName="p">{`IEnumerable<>`}</inlineCode>{`) will be generated as an `}<inlineCode parentName="p">{`ArraySchema`}</inlineCode>{`. To only process unique string inputs, any type implementing the `}<inlineCode parentName="p">{`ISet<>`}</inlineCode>{` interface can be used.`}</p>
    <h3>{`Mapping in KOPE`}</h3>
    <p>{`Arrays will be rendered as tables in KOPE, with each property of the type being mapped to a column.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "43.40277777777778%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAACKklEQVQoz53P3UtTARzG8XMVXrTZlm4et+k5R7c2rTBdKgpJb/YCRWR/jFfDaaigHg9EF0EvhNBFt2ngvPBMqKAL6aIgMKKYzTa3s5dzNl+yvuFZUNf94APP1cPzE37kUuynPnCQT/Frt8rPHYuDqgn7O3z++J7V5SWSiZfoiUX0xBKry4skE0us6askdd22lkyysrLCxsYGQu7tc4z1F1hf19nbMdirGljlbWCfOXUWt0ekWW7Hq7ThlRUaJZmA34coirgavRz3NOFt9lFXV0csFkPYSr0jky+QyWbIfkmSSX9iczNlF8/P3MXja2Ggv4trfWGuRk9w5ewJbg1EuN4X4fyZEBe7Q1yKRmhoaGB8fBwhm82Rz+UwzQrl4ndMs0yxZAIHaOoUHtFPVyRIb6dCtEOhJyzTE5aIRmR6OxRb/6kgLpeLeDyOkE6nydmFFiXjG2a5SLFYsl9W56YRW2TaT3YS7AjRHmpDliRaJQnRH0D0+RF9AQKtEg6Hg/jYGIJhGBQKBcqmhVnc+mch3NOmGIyKjNyIcHu4jZHLCneGFW5ekAkFFWRZRlEOcxC3283ExARCpVKhpkqlvGUrF9LALjPTMcLKEYYGmxiKuhjqrudcdz0Dp524jjk56nDgdDrsdwVBYHR09O/CmsOcJ5/fZm+3ypvXr3hwf5pnT+ZYeDTLwuOapw9n0DQVVZ1nXlXRNI3JyUl0XUcolUpYloVpmn/U8uHi/7nfUJHuoWC7hVEAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "files",
        "title": "files",
        "src": "/static/780880961f95184bd3334f65e184e52b/3cbba/tables.png",
        "srcSet": ["/static/780880961f95184bd3334f65e184e52b/7fc1e/tables.png 288w", "/static/780880961f95184bd3334f65e184e52b/a5df1/tables.png 576w", "/static/780880961f95184bd3334f65e184e52b/3cbba/tables.png 1152w", "/static/780880961f95184bd3334f65e184e52b/4c487/tables.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System.Collections.Generic;

namespace kope.Demo
{
    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static bool IsCollectionEmpty(ICollection<int> input)
            => input.Count == 0;

        [kopeActivity]
        public static int ArrayLength(double[] input)
            => input.Length;

        [kopeActivity]
        public static bool NestedArray(string[][] input)
            => true;

        [kopeActivity]
        public static int UniqueStringValues(HashSet<string> inputs)
            => inputs.Count;
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "IsCollectionEmpty",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "integer"
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.IsCollectionEmpty",
    "language": "csharp"
  },
  {
    "name": "ArrayLength",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "number"
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "integer"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.ArrayLength",
    "language": "csharp"
  },
  {
    "name": "NestedArray",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "input": {
          "type": "array",
          "items": [
            {
              "type": "array",
              "items": [
                {
                  "type": "string"
                }
              ],
              "uniqueItems": false
            }
          ],
          "uniqueItems": false
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "boolean"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.NestedArray",
    "language": "csharp"
  },
  {
    "name": "UniqueStringValues",
    "version": "1.0.0.0",
    "input": {
      "type": "object",
      "properties": {
        "inputs": {
          "type": "array",
          "items": [
            {
              "type": "string"
            }
          ],
          "uniqueItems": true
        }
      },
      "additionalProperties": false
    },
    "output": {
      "type": "integer"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.UniqueStringValues",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Objects`}</h2>
    <p>{`Any instance from a public, non-static class will get mapped to an `}<inlineCode parentName="p">{`ObjectSchema`}</inlineCode>{`, resursively mapping each property type to their corresponding schemas if a generator exists for that type.`}</p>
    <p>{`Unlike well-formed JSON Schemas, this simplified generation will not use the `}<inlineCode parentName="p">{`definitions`}</inlineCode>{` property to declare duplicate schemas (i.e. referencing same class will result on a duplicate schema, see below). This also result on inheriting classes to contain all its parent properties.`}</p>
    <p>{`Class properties can also define a default value as well as a description, by using `}<inlineCode parentName="p">{`System.ComponentModel.DefaultValueAttribute`}</inlineCode>{` and `}<inlineCode parentName="p">{`System.ComponentModel.DescriptionAttribute`}</inlineCode>{` correspondingly, as well as any other attribute provided by kope.Workflows.SDK.`}</p>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;
using System.Collections.Generic;

namespace kope.Demo
{
    public class Person
    {
        [Description("Name of a person")]
        [DefaultValue("John")]
        public string Name { get; set; }

        [Description("Age of a person")]
        [RangeStep(0, 120, Step = 1)]
        public int Age { get; set; }
    }

    public class Employee : Person
    {
        public double Salary { get; set; }
    }

    public class ManagedEmployee : Employee
    {
        public Boss Boss { get; set; }
    }

    public class Boss : Employee
    {
        public ICollection<Employee> Employees { get; set; }
    }

    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static string PersonName(this Person person)
            => person.Name;

        [kopeActivity]
        public static int EmployeeCount(this Boss boss)
            => boss.Employees.Count;

        [kopeActivity]
        public static string BossName(this ManagedEmployee employee)
            => $"{employee.Boss.Name} is {employee}'s boss";
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "PersonName",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "person": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string",
              "default": "John",
              "title": "name",
              "description": "Name of a person"
            },
            "age": {
              "type": "integer",
              "multipleOf": 1,
              "minimum": 0,
              "maximum": 120,
              "title": "age",
              "description": "Age of a person"
            }
          },
          "required": [
            "age"
          ],
          "title": "kope.Demo.Person"
        }
      },
      "required": [
        "person"
      ]
    },
    "output": {
      "type": "string",
      "title": "PersonNameOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.PersonName",
    "language": "csharp"
  },
  {
    "name": "EmployeeCount",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "boss": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string",
              "default": "John",
              "title": "name",
              "description": "Name of a person"
            },
            "age": {
              "type": "integer",
              "multipleOf": 1,
              "minimum": 0,
              "maximum": 120,
              "title": "age",
              "description": "Age of a person"
            },
            "salary": {
              "type": "number",
              "title": "salary"
            },
            "employees": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "default": "John",
                      "title": "name",
                      "description": "Name of a person"
                    },
                    "age": {
                      "type": "integer",
                      "multipleOf": 1,
                      "minimum": 0,
                      "maximum": 120,
                      "title": "age",
                      "description": "Age of a person"
                    },
                    "salary": {
                      "type": "number",
                      "title": "salary"
                    }
                  },
                  "required": [
                    "salary"
                  ],
                  "title": "kope.Demo.Employee"
                }
              ],
              "uniqueItems": false,
              "title": "employees"
            }
          },
          "required": [
            "employees"
          ],
          "title": "kope.Demo.Boss"
        }
      },
      "required": [
        "boss"
      ]
    },
    "output": {
      "type": "integer",
      "title": "EmployeeCountOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.EmployeeCount",
    "language": "csharp"
  },
  {
    "name": "BossName",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "employee": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string",
              "default": "John",
              "title": "name",
              "description": "Name of a person"
            },
            "age": {
              "type": "integer",
              "multipleOf": 1,
              "minimum": 0,
              "maximum": 120,
              "title": "age",
              "description": "Age of a person"
            },
            "salary": {
              "type": "number",
              "title": "salary"
            },
            "boss": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string",
                  "default": "John",
                  "title": "name",
                  "description": "Name of a person"
                },
                "age": {
                  "type": "integer",
                  "multipleOf": 1,
                  "minimum": 0,
                  "maximum": 120,
                  "title": "age",
                  "description": "Age of a person"
                },
                "salary": {
                  "type": "number",
                  "title": "salary"
                },
                "employees": {
                  "type": "array",
                  "items": [
                    {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string",
                          "default": "John",
                          "title": "name",
                          "description": "Name of a person"
                        },
                        "age": {
                          "type": "integer",
                          "multipleOf": 1,
                          "minimum": 0,
                          "maximum": 120,
                          "title": "age",
                          "description": "Age of a person"
                        },
                        "salary": {
                          "type": "number",
                          "title": "salary"
                        }
                      },
                      "required": [
                        "salary"
                      ],
                      "title": "kope.Demo.Employee"
                    }
                  ],
                  "uniqueItems": false,
                  "title": "employees"
                }
              },
              "required": [
                "employees"
              ],
              "title": "kope.Demo.Boss"
            }
          },
          "required": [
            "boss"
          ],
          "title": "kope.Demo.ManagedEmployee"
        }
      },
      "required": [
        "employee"
      ]
    },
    "output": {
      "type": "string",
      "title": "BossNameOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.BossName",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Units`}</h2>
    <p>{`KOPE uses `}<a parentName="p" {...{
        "href": "https://github.com/angularsen/UnitsNet"
      }}>{`UnitsNet`}</a>{` to provide measurement units. This allow developers to work with numeric values and conversion methods.`}<br /><br /></p>
    <p>{`Since units in `}<inlineCode parentName="p">{`UnitsNet`}</inlineCode>{` are `}<inlineCode parentName="p">{`struct`}</inlineCode>{` objects and thus cannot have default values, the `}<inlineCode parentName="p">{`unitDefault`}</inlineCode>{` attribute can be used to specify the given default value and unit to use for the unit input.`}<br /><br /></p>
    <p>{`To specify a valid unit for the input unit type, a static `}<inlineCode parentName="p">{`Units`}</inlineCode>{` class is provided on package `}<inlineCode parentName="p">{`kope.Workflows.SDK.UnitsGeneration`}</inlineCode>{`. This class contains constants for each of the supported units by unit type.`}</p>
    <h3>{`Attribute Modifiers `}<br /><br /></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.UnitDefaultAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defines a default value to be used for the unit parameter.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;
using System;
using UnitsNet;

namespace kope.Demo
{
    public static class AwesomeActivities
    {
        [kopeActivity]
        public static Area RectangleArea(Length length, Length width)
        {
            return Area.FromSquareMeters(length.Meters * width.Meters);
        }

        [kopeActivity]
        public static Area CircleArea(
            [UnitDefault(2d, Units.Length.Millimeter)] Length radius)
        {
            return Area.FromSquareMeters(Math.PI * Math.Pow(radius.Meters, 2));
        }
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "RectangleArea",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "length": {
          "unitType": "length",
          "type": "object"
        },
        "width": {
          "unitType": "length",
          "type": "object"
        }
      },
      "required": ["length", "width"]
    },
    "output": {
      "unitType": "area",
      "type": "object"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeActivities.RectangleArea",
    "language": "csharp"
  },
  {
    "name": "CircleArea",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "radius": {
          "value": 2.0,
          "unitType": "length",
          "unit": "millimeter",
          "type": "object"
        }
      }
    },
    "output": {
      "unitType": "area",
      "type": "object"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeActivities.CircleArea",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Files`}</h2>
    <p>{`Any `}<inlineCode parentName="p">{`FileInfo`}</inlineCode>{` input is mapped to a `}<inlineCode parentName="p">{`SourceFileSchema`}</inlineCode>{`. If a Revit file (`}<inlineCode parentName="p">{`.rvt`}</inlineCode>{`) is the intended source for the model, a `}<inlineCode parentName="p">{`RevitFileParameterAttribute`}</inlineCode>{` can be used to decorate the parameter.`}</p>
    <h3>{`Mapping in KOPE `}<br /><br /></h3>
    <p>{`Files without extension restrictions show all files available in KOPE Workspace.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaUlEQVQoz5XRv07CUBQG8C5sBEWayELizqgv4Myi8i/iK7jo5HO46KSLiBRT5Rl4AoiJtihtKZTSe3tbWgqFe2nVKiZGjPjLl5zp5OZ8l1ItoIK+67qTgB+YTqez2cz/CyVbPRVpBjIsyyKEeJ7n+z7GeKllCchCWzRNE31HCFniZagIbVGfgxBCACCEGGMyhxfxPI/qol5T4HUAINAGA2Pk2DoyAADL3Yz6dY7nePG51ekoEOh2V1ENw+Q4/qpcua7cF29umR9KpZIsy1QXPPCNC6leVB4ZrcmCl2q71cBT5/zsNLER3dxKJJP0yjq9Sq/F43QsFg2Hw5FIJBQKsSxL9bQ64C9tsWxL5aHE2BKjKU8Td1i9Yw6y2yfH6aPD3XQut5POZrJ7mXw6l88W9gupVKpWq1FtXeUFGUBLN5zPIBNCHWMydokzws54YV/vf0npFlL7immirxiBeWFekF8K+xje/70tvwI2kT4U+azLkQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "files",
        "title": "files",
        "src": "/static/607f632bd2f91145fb98fa398f135b45/3cbba/files-all.png",
        "srcSet": ["/static/607f632bd2f91145fb98fa398f135b45/7fc1e/files-all.png 288w", "/static/607f632bd2f91145fb98fa398f135b45/a5df1/files-all.png 576w", "/static/607f632bd2f91145fb98fa398f135b45/3cbba/files-all.png 1152w", "/static/607f632bd2f91145fb98fa398f135b45/4c487/files-all.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Files tagged with attributes might impose restrictions, such as `}<inlineCode parentName="p">{`.rvt`}</inlineCode>{` extension.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjUlEQVQoz43RwW7TQBAGYN/IsY1U0YooVXkApErtoRLHnFDSpu4DgBBIfYlabQJC6hP0kDfgEHiEXCskeqibBLklseONuzu7s3ac4OK1ARuBekAkn0Zz+2dGGo0G4Hok8IM4jqMoustFUZQkyY95NDKhPBBhEM5mM6VUrFSapnFO/RH/S5qm2pC71/ZXzoAx5uek9BExSdT8zQ5zB/ZQIgqUAAjAKWVhOD0//3TUfHvy7tQ4fmMYjcZJppFrNpuGYZimqY04ueqZoxHxXAvGfQ4ecQfx97DVOtt4vLyzU97afvhkc3Wl/Gh9o1wqrS0vLRWLxULhQbvd1mzufjYvet2+9eXStbuMEmdofZvixw/v69Wnh69rr14+e/F8r6rv1w/02l51d7em63qlUul0Ohrxby3nxrHtW0pRBn4w4Tw7PorupD9FmZWUYTiZZHWPUkoj6DlkBAyE4IjIOaeUAsBCr3I4sW6ugQEiCiFE3gFA5T/7jyxMA068MQr8S+QW2vx7TJIk6p5Fkr/CPwEwijqfo5ejJgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "files",
        "title": "files",
        "src": "/static/4be3ddfd2898368ff518fbd6c95aeb48/3cbba/files-Revit.png",
        "srcSet": ["/static/4be3ddfd2898368ff518fbd6c95aeb48/7fc1e/files-Revit.png 288w", "/static/4be3ddfd2898368ff518fbd6c95aeb48/a5df1/files-Revit.png 576w", "/static/4be3ddfd2898368ff518fbd6c95aeb48/3cbba/files-Revit.png 1152w", "/static/4be3ddfd2898368ff518fbd6c95aeb48/4c487/files-Revit.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Attribute Modifiers `}<br /><br /></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.FileParameterAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies the allowed file extensions for the `}<inlineCode parentName="td">{`FileParameterDefinition`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.RevitFileParameterAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Explicitly defines the output parameter as `}<inlineCode parentName="td">{`RevitFileParameterDefinition`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;
using System.IO;

namespace kope.Demo
{
    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static bool AnyExtensionFileInfo(FileInfo file)
            => file != null;

        [kopeActivity]
        public static bool MultiExtensionFileInfo([FileParameter(".json", ".glb")] FileInfo file)
            => file != null;

        [kopeActivity]
        public static bool ExplicitRevitExtensionFileInfo([RevitFileParameter] FileInfo file)
            => file != null;
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "AnyExtensionFileInfo",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "file": {
          "type": "object",
          "properties": {
            "extension": {
              "type": "string",
              "enum": [],
              "description": "File extension"
            },
            "derivates": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "description": "File full name, including extension"
                    },
                    "url": {
                      "type": "string",
                      "format": "uri",
                      "description": "Storage URL location for the corresponding file"
                    }
                  },
                  "required": ["name", "url"]
                }
              ],
              "description": "Set of derivate files derived from the source file"
            },
            "processStatus": {
              "type": "string",
              "enum": ["none", "pending", "processing", "failed", "completed"],
              "description": "Value representing the process status of a file"
            },
            "name": {
              "type": "string",
              "description": "File full name, including extension"
            },
            "url": {
              "type": "string",
              "format": "uri",
              "description": "Storage URL location for the corresponding file"
            }
          },
          "required": ["extension", "name", "url"],
          "title": "file"
        }
      },
      "required": ["file"]
    },
    "output": {
      "type": "boolean",
      "title": "AnyExtensionFileInfoOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.AnyExtensionFileInfo",
    "language": "csharp"
  },
  {
    "name": "MultiExtensionFileInfo",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "file": {
          "type": "object",
          "properties": {
            "extension": {
              "type": "string",
              "enum": [".json", ".glb"],
              "description": "File extension"
            },
            "derivates": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "description": "File full name, including extension"
                    },
                    "url": {
                      "type": "string",
                      "format": "uri",
                      "description": "Storage URL location for the corresponding file"
                    }
                  },
                  "required": ["name", "url"]
                }
              ],
              "description": "Set of derivate files derived from the source file"
            },
            "processStatus": {
              "type": "string",
              "enum": ["none", "pending", "processing", "failed", "completed"],
              "description": "Value representing the process status of a file"
            },
            "name": {
              "type": "string",
              "description": "File full name, including extension"
            },
            "url": {
              "type": "string",
              "format": "uri",
              "description": "Storage URL location for the corresponding file"
            }
          },
          "required": ["extension", "name", "url"],
          "title": "file"
        }
      },
      "required": ["file"]
    },
    "output": {
      "type": "boolean",
      "title": "MultiExtensionFileInfoOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.MultiExtensionFileInfo",
    "language": "csharp"
  },
  {
    "name": "ExplicitRevitExtensionFileInfo",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "file": {
          "type": "object",
          "properties": {
            "extension": {
              "type": "string",
              "enum": [".rvt"],
              "description": "File extension"
            },
            "derivates": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "description": "File full name, including extension"
                    },
                    "url": {
                      "type": "string",
                      "format": "uri",
                      "description": "Storage URL location for the corresponding file"
                    }
                  },
                  "required": ["name", "url"]
                }
              ],
              "description": "Set of derivate files derived from the source file"
            },
            "processStatus": {
              "type": "string",
              "enum": ["none", "pending", "processing", "failed", "completed"],
              "description": "Value representing the process status of a file"
            },
            "name": {
              "type": "string",
              "description": "File full name, including extension"
            },
            "url": {
              "type": "string",
              "format": "uri",
              "description": "Storage URL location for the corresponding file"
            }
          },
          "required": ["extension", "name", "url"],
          "title": "file"
        }
      },
      "required": ["file"]
    },
    "output": {
      "type": "boolean",
      "title": "ExplicitRevitExtensionFileInfoOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.ExplicitRevitExtensionFileInfo",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`Models`}</h2>
    <p>{`A Hypar `}<inlineCode parentName="p">{`Elements.Model`}</inlineCode>{` parameter can be used as an argument type, which will map it as a `}<inlineCode parentName="p">{`SourceFileSchema`}</inlineCode>{`, with a predefined set of allowed file types.`}<br /><br /></p>
    <p>{`If a Revit file (`}<inlineCode parentName="p">{`.rvt`}</inlineCode>{`) is the intended source for the model, a `}<inlineCode parentName="p">{`RevitFileParameterAttribute`}</inlineCode>{` can be used to decorate the parameter, resulting on a Revit-only allowed file type. Internally, KOPE will try to convert any Revit (`}<inlineCode parentName="p">{`.rvt`}</inlineCode>{`) file to a Hypar `}<inlineCode parentName="p">{`Elements.Model`}</inlineCode>{` for internal use.`}<br /><br /></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdElEQVQoz1WSXU/TcBSH+ylI1M2IxMQl3uA6mKAQBF0GATQxkBA/gF4Yb0y8Z1dGIUo0LHvDQOJIdKnRkbhR+bcZWNSNTbZmLbbdS9duiIN1bOvWwWqGu9Anv3Nybp78bg6U3EsXC4XD0qGi1BSlpqpqmgwG3sxFva93vgE+I2Sz2d0TcrmcIAjJZJKm6WBoi2VZiNvlJamUPyiUy3KpVFGUejjy4+nM7Ntltw/9TBDE9vY2wzAsyzadYBAAgCDIy1fzfr8fSkTnSvRMiZk73HlRpJ6URX+M3HHarJ8+vie+ECRJcokESZI4jq+vr9M0HQqFAAALCy4cx6BflKVKPpTjj2rU40r0vswvx+KMy2n3r3z4HgyGw2EEQdxu99LSEkEQHMdFIhEMwxYXF3Ech2j2614mdpCL7GfJPSFayGdika35588QzzsURQOBwMoJ8XicoiiCIAAAPp/ParWiKAqlcuLvfHE/n5OkYqFYKcs1Jh5dcNj9q6sej2d6etputzscDq/Xa7PZLBaLy+VaW1tzOp0YhkH1ulKv1xV5PyumeT4pihmeYziWSaWS6XRaFAVByDQn09yCIPA8L0mSLMvVahVqNBqqqlbL+cm7U12DNwwDN00DfdeMRthwpbu7p/NyNwx3/QsMw5ubm6qqHh8ft2S5nB8a6jMPtt8evfBg8vy9O+0TYx1Ttzomhs9dunj61BmNVqvRas9qNJq2tjYAgKqqR0dHLblWq46Pmq736cZH4RGz3mzSj5iNpqHOsWF9f79B3wX39Bh7e68aDAadTrexsdFqVk9oNBqpFE/RLEVzNM3SzaOVn80H+Y9KpfLX+gPo5NamUjRRVAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "models",
        "title": "models",
        "src": "/static/f030bbf1c07aae69e999cf69eb82deca/3cbba/models-inputs-outputs.png",
        "srcSet": ["/static/f030bbf1c07aae69e999cf69eb82deca/7fc1e/models-inputs-outputs.png 288w", "/static/f030bbf1c07aae69e999cf69eb82deca/a5df1/models-inputs-outputs.png 576w", "/static/f030bbf1c07aae69e999cf69eb82deca/3cbba/models-inputs-outputs.png 1152w", "/static/f030bbf1c07aae69e999cf69eb82deca/4c487/models-inputs-outputs.png 1280w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Attribute Modifiers `}<br /><br /></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.RevitFileParameterAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Explicitly defines the model source to be a Revit (`}<inlineCode parentName="td">{`.rvt`}</inlineCode>{`) file.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using Elements;
using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;

namespace kope.Demo
{
    public static class AwesomeStaticClass
    {
        [kopeActivity]
        public static bool HyparModel(Model file)
            => file != null;

        [kopeActivity]
        public static bool ExplicitRevitSourceModel([RevitFileParameter] Model file)
            => file != null;
    }
}
`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "HyparModel",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "file": {
          "type": "object",
          "properties": {
            "extension": {
              "type": "string",
              "enum": [".json"],
              "default": ".json",
              "description": "Model file extension"
            },
            "exports": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "description": "File full name, including extension"
                    },
                    "url": {
                      "type": "string",
                      "format": "uri",
                      "description": "Storage URL location for the corresponding file"
                    }
                  },
                  "required": ["name", "url"]
                }
              ],
              "description": "Set of model files derived from the source file"
            },
            "processStatus": {
              "type": "string",
              "enum": ["none", "pending", "processing", "failed", "completed"],
              "description": "Value representing the process status of a file"
            },
            "name": {
              "type": "string",
              "description": "File full name, including extension"
            },
            "url": {
              "type": "string",
              "format": "uri",
              "description": "Storage URL location for the corresponding file"
            }
          },
          "required": ["extension", "exports", "name", "url"],
          "title": "file"
        }
      },
      "required": ["file"]
    },
    "output": {
      "type": "boolean",
      "title": "HyparModelOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.HyparModel",
    "language": "csharp"
  },
  {
    "name": "ExplicitRevitSourceModel",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "file": {
          "type": "object",
          "properties": {
            "extension": {
              "type": "string",
              "enum": [".rvt", ".json"],
              "default": ".json",
              "description": "Model file extension"
            },
            "exports": {
              "type": "array",
              "items": [
                {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string",
                      "description": "File full name, including extension"
                    },
                    "url": {
                      "type": "string",
                      "format": "uri",
                      "description": "Storage URL location for the corresponding file"
                    }
                  },
                  "required": ["name", "url"]
                }
              ],
              "description": "Set of model files derived from the source file"
            },
            "processStatus": {
              "type": "string",
              "enum": ["none", "pending", "processing", "failed", "completed"],
              "description": "Value representing the process status of a file"
            },
            "name": {
              "type": "string",
              "description": "File full name, including extension"
            },
            "url": {
              "type": "string",
              "format": "uri",
              "description": "Storage URL location for the corresponding file"
            }
          },
          "required": ["extension", "exports", "name", "url"],
          "title": "file"
        }
      },
      "required": ["file"]
    },
    "output": {
      "type": "boolean",
      "title": "ExplicitRevitSourceModelOutput"
    },
    "scriptFile": "kope.Workflows.SDK.Sample.dll",
    "entryPoint": "kope.Demo.AwesomeStaticClass.ExplicitRevitSourceModel",
    "language": "csharp"
  }
]
`}</code></pre>
    <h2>{`GeoJSON`}</h2>
    <p>{`KOPE supports any third party library that provides GeoJSON support, as long as they natively handle serialisation to valid GeoJson schemas as defined in `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc7946"
      }}>{`The GeoJSON Specification (RFC 7946)`}</a></p>
    <br />
    <InlineNotification mdxType="InlineNotification">
      <p>{`The `}<inlineCode parentName="p">{`GeoJsonAttribute`}</inlineCode>{` will only replace the otherwise autogenerated schema from source parameter type for the specified GeoJSON schema. External GeoJSON libraries might not provide a direct representation of each GeoJSON types and instead rely on Newtonsoft.Json serialisation to convert to/from standard schemas to their own types.`}<br /><br /></p>
      <p><strong parentName="p">{`Please make sure chosen libraries properly work with Newtonsoft.Json and they correctly support GeoJSON standard schemas.`}</strong></p>
    </InlineNotification>
    <h3>{`Known External GeoJSON libraries `}<br /><br /></h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/hypar-io/Elements"
        }}>{`Hypar Elements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/GeoJSON-Net/GeoJSON.Net"
        }}>{`GeoJSON.Net`}</a></li>
    </ul>
    <h3>{`Attribute Modifiers `}<br /><br /></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kope.Workflows.SDK.Attributes.GeoJSONAttribute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows to specify which GeoJson schema to use for the given input.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`C# Example `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using Elements.Geometry;
using Elements.Spatial;
using kope.Workflows.SDK;
using kope.Workflows.SDK.Attributes;
using kope.Workflows.SDK.Types;
using System.Linq;
using GeoPoint = Elements.GeoJSON.Point;
using GeoPolygon = Elements.GeoJSON.Polygon;
using GeoPosition = Elements.GeoJSON.Position;

namespace kope.Workflow.SDK.CodeGeneration.Debug
{
    /// <summary>
    /// GeoJSON related activities
    /// </summary>
    // ReSharper disable once InconsistentNaming
    public static class GeoJSONActivities
    {
        [kopeActivity]
        public static double GetHyparPolygonArea(
            [GeoJSON(GeoJSONSchema.Polygon)] GeoPolygon polygon)
            => polygon
                .ToPolygons()
                .Sum(p => p.Area());

        [kopeActivity]
        [return: GeoJSON(GeoJSONSchema.Polygon)]
        public static GeoPolygon CreateSquarePolygonFromCenter(
            [GeoJSON(GeoJSONSchema.Point)] GeoPoint location,
            double squareSideMeters)
        {
            var center = location.Coordinates.ToVectorMeters();
            var halfSide = squareSideMeters * 0.5;

            var coordinates = new Vector3[4]
            {
                new Vector3(center.X + halfSide, center.Y + halfSide),
                new Vector3(center.X + halfSide, center.Y - halfSide),
                new Vector3(center.X - halfSide, center.Y - halfSide),
                new Vector3(center.X - halfSide, center.Y + halfSide)
            };

            var edges = new GeoPosition[1][]
            {
                new[]
                {
                    coordinates[0].ToPositionMeters(),
                    coordinates[1].ToPositionMeters(),
                    coordinates[2].ToPositionMeters(),
                    coordinates[3].ToPositionMeters(),
                    coordinates[0].ToPositionMeters()
                },
            };

            return new GeoPolygon(edges);
        }

        private static GeoPosition ToPositionMeters(this Vector3 point)
            => new GeoPosition(MercatorProjection.YToLat(point.Y), MercatorProjection.XToLon(point.X));
    }
}

`}</code></pre>
    <h3>{`JSON Schema `}<br /><br /></h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  {
    "name": "GetHyparPolygonArea",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "polygon": {
          "type": "object",
          "properties": {
            "type": {
              "type": "string",
              "enum": ["Polygon"]
            },
            "coordinates": {
              "type": "array",
              "items": [
                {
                  "type": "array",
                  "items": [
                    {
                      "type": "array",
                      "items": [
                        {
                          "type": "number"
                        }
                      ],
                      "minItems": 2
                    }
                  ],
                  "minItems": 4
                }
              ]
            },
            "bbox": {
              "type": "array",
              "items": [
                {
                  "type": "number"
                }
              ],
              "minItems": 4
            }
          },
          "required": ["type", "coordinates"],
          "title": "GeoJSON Polygon"
        }
      },
      "required": ["polygon"]
    },
    "output": {
      "type": "number",
      "title": "GetHyparPolygonAreaOutput"
    },
    "scriptFile": "kope.Workflow.SDK.CodeGeneration.Debug.dll",
    "entryPoint": "kope.Workflow.SDK.CodeGeneration.Debug.GeoJSONActivities.GetHyparPolygonArea",
    "language": "csharp"
  },
  {
    "name": "CreateSquarePolygonFromCenter",
    "version": "1.0.0",
    "input": {
      "type": "object",
      "properties": {
        "location": {
          "type": "object",
          "properties": {
            "type": {
              "type": "string",
              "enum": ["Point"]
            },
            "coordinates": {
              "type": "array",
              "items": [
                {
                  "type": "number"
                }
              ],
              "minItems": 2
            },
            "bbox": {
              "type": "array",
              "items": [
                {
                  "type": "number"
                }
              ],
              "minItems": 4
            }
          },
          "required": ["type", "coordinates"],
          "title": "GeoJSON Point"
        },
        "squareSideMeters": {
          "type": "number",
          "title": "squareSideMeters"
        }
      },
      "required": ["location", "squareSideMeters"]
    },
    "output": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["Polygon"]
        },
        "coordinates": {
          "type": "array",
          "items": [
            {
              "type": "array",
              "items": [
                {
                  "type": "array",
                  "items": [
                    {
                      "type": "number"
                    }
                  ],
                  "minItems": 2
                }
              ],
              "minItems": 4
            }
          ]
        },
        "bbox": {
          "type": "array",
          "items": [
            {
              "type": "number"
            }
          ],
          "minItems": 4
        }
      },
      "required": ["type", "coordinates"],
      "title": "GeoJSON Polygon"
    },
    "scriptFile": "kope.Workflow.SDK.CodeGeneration.Debug.dll",
    "entryPoint": "kope.Workflow.SDK.CodeGeneration.Debug.GeoJSONActivities.CreateSquarePolygonFromCenter",
    "language": "csharp"
  }
]
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      